.css-7wcoc8-MuiAccordionDetails-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* background-color: #000; */
}

span.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary.MuiIconButton-sizeMedium.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.css-wbuiqy-MuiButtonBase-root-MuiIconButton-root-PrivateSwitchBase-root-MuiCheckbox-root {
  display: none !important;
}

.MuiContainer-root.MuiContainer-maxWidthMd.css-v5194a-MuiContainer-root {
  padding-left: 8px;
  padding-right: 8px;
}
.css-mfpt21-MuiTableCell-root:first-of-type {
  padding-bottom: 4px;
}

.css-mfpt21-MuiTableCell-root:first-of-type {
  padding-left: 20px;
}
.css-mfpt21-MuiTableCell-root {
  padding: 0;
}
.css-18qr5zf-MuiTableCell-root:last-child {
  padding-right: 8px;
}
.css-18qr5zf-MuiTableCell-root {
  padding: 0px;
}

.css-v5194a-MuiContainer-root {
  margin-bottom: 0px;
  margin-top: 0;
}

.menu-profile {
  margin: auto;
  border-radius: 50%;
  border: 2px solid;
  width: 180px;
  height: 180px;
  @media only screen and (max-width: 600px) {
    width: 148px;
    height: 148px;
  }
}
/* firebase hosting:channel:deploy channel_name */

/* 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 */

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
